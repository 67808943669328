<template>
	<NuxtLink
		:to="`/${link}`"
		:class="[
			{ 'first:md:w-full first:max-w-full group' : expandFirstArticle },
			'w-full md:w-1/2 lg:w-1/3'
		]"
	>
		<div
			:class="[
				expandFirstArticle ?
					'group-first:w-full group-first:flex group-first:md:flex-row group-first:rounded-2xl group-first:h-[600px] group-first:md:h-[360px] group-first:lg:h-[320px]' :
					'lg:h-[420px] md:min-h-[420px] min-h-[316px]',
				cardClasses,
				'flex flex-col overflow-hidden border border-gray-200 rounded-lg shadow hover:shadow-lg bg-white'
			]"
		>
			<NuxtImg
				:class="[
					'object-cover w-full h-1/2',
					{ 'group-first:md:w-1/2 group-first:md:h-full group-first:h-2/5' : expandFirstArticle }
				]"
				:src="imageSrc + '/m/300x150 300w'"
				:alt="imageAlt"
				:srcset="imageSrc + '/m/400x200 400w, ' + imageSrc + '/m/600x300 600w, ' + imageSrc + '/m/300x150 300w'"
				:loading="loading"
			/>
			<div class="px-8 py-6">
				<div
					:class="[
						{ 'group-first:text-lg group-first:md:h-auto' : expandFirstArticle },
						'font-bold line-clamp-2 md:h-[48px]'
					]"
				>
					{{ title }}
				</div>
				<div
					:class="[
						'pt-4 text-sm line-clamp-4',
						{ 'group-first:line-clamp-[8] group-first:lg:line-clamp-6' : expandFirstArticle }
					]"
					v-html="renderRichText(preview)"
				/>
				<span
					v-if="expandFirstArticle"
					class="hidden text-sm text-red-500 group-first:block group-hover:underline underline-offset-2 decoration-2 decoration-mx-orange"
				>
					Read More
				</span>
				<div class="pt-4">
					{{ getDate(date, locale) }}
				</div>
			</div>
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { getDate } from '@/utils/getDate'
import type { BlogArticleProps } from '@/components/blog/types'

const { locale } = useI18n({
	useScope: 'global'
})

const props = defineProps({
	article: {
		type: Object as PropType<BlogArticleProps>,
		required: true
	},
	expandFirstArticle: {
		type: Boolean,
		default: false
	},
	cardClasses: {
		type: String,
		default: ''
	},
	lazyLoad: {
		type: Boolean,
		default: false
	}
})

const { article, lazyLoad } = toRefs(props)

const imageSrc = computed(() => {
	return article.value?.image?.filename || ''
})

const imageAlt = computed(() => {
	return article.value?.image?.alt || ''
})

const date = computed(() => {
	return article.value?.dateTime || ''
})

const link = computed(() => {
	return article.value?.link || ''
})

const title = computed(() => {
	return article.value?.title || ''
})

const preview = computed(() => {
	return article.value?.preview || ''
})
const loading = computed(() => {
	return lazyLoad.value ? 'lazy' : undefined
})
</script>
