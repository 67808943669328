import type { BlogArticleProps } from '@/components/blog/types'

export function mapBlogArticles (stories: any) {
	return stories?.map((story: any): BlogArticleProps => {
		const { image, title, dateTime, preview } = story.content
		return {
			link: story.full_slug,
			uuid: story.uuid,
			image,
			title,
			dateTime,
			preview
		}
	})
}

export default { mapBlogArticles }
